import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";

@Component
export default class DfPdfViewerComponent extends Vue {
  get logoUrl(): string {
    return Utils.getPropertyImageUrl(this.$store.getters.signboardContents[0], "LOGO", null);
  }

  get backUrl(): string {
    return Utils.getPropertyValue(this.$store.getters.signboardContents[0], "URL", "STRING");
  }
}
